// This is the template for blog posts (single as per WP convention)

import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import BlogEntryList from '../../components/BlogEntryList/BlogEntryList';
import CTASectionTrial from '../../components/CTASectionTrial/CTASectionTrial';
import DefaultPageHeader from '../../components/DefaultPageHeader/DefaultPageHeader';
import GatsbyImageAdaptor from '../../components/GatsbyImageAdaptor/GatsbyImageAdaptor';
import PageSection from '../../components/PageSection/PageSection';
import PageTemplate from '../../components/PageTemplate/PageTemplate';
import { PageComponent } from '../../types/gatsby.types';
import { ColorPalette } from '../../utils/colors.utils';
import './single.scss';

// Notice filter ne (notequal) in allMdx query, using the id of the current post)

// Can't use query variables inside Static Queries
// https://www.gatsbyjs.com/docs/tutorial/part-6/

// It seems without static queries the autogenerated query types won't work as well, investigate later

export const query = graphql`
  query singleBlogPostQuery ($id: String) {
    singlePost: mdx(id: {eq: $id}, fileAbsolutePath: {regex: "/blog/"}) {
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        featured_image_alt
        featured_image {
          childImageSharp {
            gatsbyImageData(aspectRatio: 1.77, layout: FULL_WIDTH)
          }
        }
      }
    }
    allPosts: allMdx(
      sort: {order: DESC, fields: frontmatter___date}
      filter: {
        id: {ne: $id}
        fileAbsolutePath: {regex: "/blog/"}
      }
      limit: 2
    ) {
      nodes {
        frontmatter {
          date(formatString: "D MMMM YYYY")
          title
          featured_image {
            childImageSharp {
              gatsbyImageData(aspectRatio: 1.77, layout: FULL_WIDTH)
            }
          }
          featured_image_alt
        }
        id
        slug
        body
      }
    }
  }
`

const BlogArticle: PageComponent = props => {

  return null;

  const featuredImage = getImage(props.data.singlePost.frontmatter.featured_image)

  return <Observer children={() => (
    <PageTemplate
      {...props} // always include
      className="BlogSingle"
      title="Blog"
      description="Trackster Blog"
      backgroundColor={ColorPalette.white}
      foregroundColor={ColorPalette.dark}
    >

      <DefaultPageHeader
        id="SingleBlogArticleHeader"
        className="SingularPageHeader" // Singular is the WP naming for both default pages + posts
        title={props.data.singlePost.frontmatter.title}
        subtitle=""
        children={
          <p className="SingleBlogArticleMeta">
            {props.data.singlePost.frontmatter.date}
          </p>
        }
      ></DefaultPageHeader>

      <PageSection id="SingleBlogArticle">
        <GatsbyImageAdaptor image={featuredImage} />

        <article className="SingleBlogArticleContent">
          <MDXRenderer>
            {props.data.singlePost.body}
          </MDXRenderer>
        </article>

      </PageSection>

      <PageSection id="BlogEntryListSection">
        <h2 className="BlogEntryListSectionHeading">Latest articles</h2>
        <BlogEntryList
        id=""
        allMdx={props.data.allPosts}
        ></BlogEntryList>
      </PageSection>

      <CTASectionTrial
      title="Learn by doing: start your 30-day trial now!"
      />

    </PageTemplate>
  )} />
}

export default BlogArticle;